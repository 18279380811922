import React from 'react';
import { iconButtonStyle } from './styles';
import { Icon } from '../Icons';

type Props = React.ComponentProps<'a'> & {
  icon: string;
};

const LinkIconButton: React.FC<Props> = ({ icon, ...props }) => (
  <a css={iconButtonStyle} {...props}>
    <Icon icon={icon} />
  </a>
);

export default LinkIconButton;
