import React from 'react';
import prettyBytes from 'pretty-bytes';
import { LinkIconButton } from '../Button';
import * as Text from '../Text';
import { fileStyle, previewStyle, textColumnStyle } from './styles';
import IconJpg from './file-icons/jpg.svg';
import IconSvg from './file-icons/svg.svg';
import IconCsv from './file-icons/csv.svg';
import IconPdf from './file-icons/pdf.svg';
import IconPng from './file-icons/png.svg';
import IconTiff from './file-icons/tiff.svg';
import IconTxt from './file-icons/txt.svg';

type Props = {
  style?: React.CSSProperties;
  className?: string;
  name: string;
  url: string;
  extension: string;
  size?: number;
};

const getExtensionIcon = (ext?: string) => {
  switch (ext) {
    case 'csv':
      return IconCsv;
    case 'jpeg':
    case 'jpg':
      return IconJpg;
    case 'png':
      return IconPng;
    case 'pdf':
      return IconPdf;
    case 'svg':
      return IconSvg;
    case 'tiff':
      return IconTiff;
    case 'txt':
      return IconTxt;
    default:
      return null;
  }
};

const getPressFilePreviewStub = (
  extension: string | undefined
): React.VFC<React.SVGProps<SVGSVGElement>> | null => {
  return getExtensionIcon(extension);
};

export const PressFileItem: React.FC<Props> = ({
  name,
  extension,
  size,
  url,
  style,
  className,
}) => {
  const PreviewImg = getPressFilePreviewStub(extension);

  return (
    <div css={fileStyle} style={style} className={className}>
      {PreviewImg && <PreviewImg css={previewStyle} />}

      <div css={textColumnStyle}>
        <Text.ContentM>{name}</Text.ContentM>
        {!!size && <Text.BodyS>{prettyBytes(size)}</Text.BodyS>}
      </div>

      <LinkIconButton
        data-test-id="PressKitDownloadLink"
        data-component="presskit-link"
        href={url}
        download={true}
        icon="download"
      />
    </div>
  );
};
