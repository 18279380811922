import React from 'react';
import { useTranslation } from 'next-i18next';
import 'moment/locale/de';
import 'moment/locale/fr';
import 'moment/locale/it';
import 'moment/locale/pt';
import 'moment/locale/es';
import moment from 'moment';
import { LinkButton, Text } from '@imus/services-ui';
import {
  tourItemStyle,
  calendarStyle,
  calendarHeaderStyle,
  textColumnStyle,
  buttonStyle,
} from './styles';

export interface TourEvent {
  name: string;
  date: string;
  url?: string;
  location: string;
}

type Props = {
  event: TourEvent;
  locale: string;
};

export const TourItem: React.FC<Props> = ({ event, locale }) => {
  const { t } = useTranslation();

  const date =
    event.date && moment(event.date).isValid()
      ? moment(event.date).locale(locale)
      : null;

  return (
    <div css={tourItemStyle}>
      <div css={calendarStyle}>
        <div css={calendarHeaderStyle}>{date && date.format('MMM')}</div>
        {date && date.date()}
      </div>

      <div css={textColumnStyle}>
        <Text.ContentM>{event.name}</Text.ContentM>
        <Text.BodyS>{event.location}</Text.BodyS>
      </div>

      {event.url && (
        <LinkButton
          data-component="tour-link"
          css={buttonStyle}
          rel="noreferrer"
          target="_blank"
          href={event.url}
        >
          {t('book')}
        </LinkButton>
      )}
    </div>
  );
};
